<template>
  <div class="pack-box" :class="`pack-box${copyright.entrantsType}`">
    <!-- <div class="gouBack" @click="goBack">
      <i class="iconfont el-icon-arrow-left" />
      {{ '返回资料包列表' }}
    </div> -->
    <!-- <iframe class="word-div" :src="packageList[0].names"></iframe> -->
    <div class="dis_flex_start ziliaoBox">
      <div class="iframeBox">
        <div class="leftBox">
          <div class="title">
            <div class="til">{{ title }}</div>
            <div class="tips">
              <div class="up">
                <img src="@/assets/img/partner/time3.png" alt="" />
                上传时间：{{ updateTime }}
              </div>
              <div class="up">
                <img src="@/assets/img/partner/down3.png" alt="" />
                下载次数：{{ downloadCount ? downloadCount : 0 }}
              </div>
              <div class="up">
                <img src="@/assets/img/partner/see3.png" alt="" />
                浏览次数：{{ viewCount ? viewCount : 0 }}
              </div>
            </div>
          </div>
          <div class="iframe">
            <iframe
              :src="names"
              width="790px"
              scrolling="no"
              class="iframe"
              pointer-events="none"
              height="721px"
              frameborder="0"
              noResize
            />
          </div>

          <div v-if="!tryWatch" class="maskLayer">
            <div class="bottom">
              <div class="shadow" />
              <div class="goBuy">
                预览已结束，如果下载本文需要<span @click="goOrder"
                  >立即购买 ></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bigBox">
        <div class="directory">
          <div class="left">
            <img src="@/assets/img/partner/mulu.png" alt="" />资料目录
          </div>
          <div class="right">
            全部下载<img src="@/assets/img/partner/down4.png" alt="" />
          </div>
        </div>
        <div class="itemBox">
          <div
            v-for="(item, index) in packageList"
            :key="index"
            @click="goTry(item)"
            class="boxItem"
          >
            <div class="contentItem">
              <div v-if="IsPurchase == 0" class="seeBtn" />
              <div class="sourceImg">
                <img
                  v-if="item.filetype == 'excel'"
                  class="imgItem"
                  src="@/assets/img/partner/xls1.png"
                />
                <img
                  v-else-if="item.filetype == 'word'"
                  class="imgItem"
                  src="@/assets/img/partner/word1.png"
                />
                <img
                  v-else-if="item.filetype == 'pdf'"
                  class="imgItem"
                  src="@/assets/img/partner/pdf1.png"
                />
                <img
                  v-else-if="item.filetype == 'ppt'"
                  class="imgItem"
                  src="@/assets/img/partner/ppt1.png"
                />
                <img
                  v-else
                  class="imgItem"
                  src="@/assets/img/study/dataFile.png"
                />
              </div>
              <div class="contentBox">
                <div class="contentTitle elp">{{ item.title }}</div>
                <!-- <div class="contentTips">{{ item.size }}</div> -->
              </div>
            </div>
            <div
              v-if="
                IsPurchase == 1 && item.seeNum && item.seeNum > 0 && userInfo
              "
              class="btnItem"
              @click="goTry(item)"
            >
              可试看
            </div>
            <div class="dis_flex">
              <div
                v-if="IsPurchase == 0"
                class="kowledge_Btn"
                @click="openKowledge(item)"
              >
                知识点
              </div>
              <div v-if="IsPurchase == 0" class="dowBtn" @click="dowInfo(item)">
                下载
              </div>
            </div>
          </div>
        </div>

        <div class="buyBox">
          <div class="price">
            <span class="symbol">￥</span>
            <span class="num">{{ price }}</span>
          </div>
          <div class="btn" @click="goOrder">立即购买</div>
        </div>
      </div>
    </div>

    <div class="brace" />
    <div v-if="IsPurchase == 1" class="buyBox">
      <div class="priceBox">
        <div class="buyBtnBox"></div>
      </div>
    </div>
    <el-dialog
      :visible.sync="kowledgePointsVisible"
      width="50%"
      :before-close="kowledgePointsClose"
      :show-close="false"
    >
      <div class="kowledgePoints_wrap">
        <kowledgePoints ref="kowledgePoints" type="4" />
        <div class="determine_btn" @click="kowledgePointsClose">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo, getToken } from '@/api/cookies'
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
import { DataClient } from '@/api/dataClient'
const dataClient = new DataClient()
import { getDomain } from '@/api/cookies'
import kowledgePoints from '@/components/kowledgePoints.vue'
export default {
  components: {
    kowledgePoints
  },
  data() {
    return {
      copyright:
        {} /* { name: "同行转型", key: 1 },    { name: "招商加盟", key: 2 },
    { name: "高校合伙人", key: 3 } */,
      id: null, // 资料包ID
      title: null, // 资料包名称
      schoolId: null, // 大类ID
      userInfo: null,
      packageList: null, // 资料列表
      IsPurchase: null, // 1 未购买 0 已购买
      price: null,
      names: null,
      tryWatch: false,
      updateTime: null,
      viewCount: null,
      downloadCount: null,
      kowledgePointsVisible: false
    }
  },
  created() {
    this.copyright = getDomain()
    this.id = this.$route.query.id
    this.userInfo = getInfo()
    this.getPackageDetails()
    this.title = this.$route.query.title
    this.IsPurchase = this.$route.query.isPurchase
    this.price = this.$route.query.num
    this.updateTime = this.$route.query.updateTime
    this.viewCount = this.$route.query.viewCount
    this.downloadCount = this.$route.query.downloadCount
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    /* 资料详情 */
    getPackageDetails() {
      const that = this
      if (this.userInfo) {
        dataClient
          .getPackageDetails(10000, 1, this.id, this.userInfo.id)
          .then((res) => {
            for (const item of res.rows) {
              item.filetype = Vue.prototype.getFileType(item.names)
              Vue.prototype.getFilesize(item.names, function (size) {
                const sizeVal = (size / 1028).toFixed(0) + 'k'
                const data = (size / 1028 / 1024).toFixed(0)
                item.size = data > 0 ? data + 'M' : sizeVal
                that.$forceUpdate()
              })
            }
            this.packageList = res.rows
            this.names = this.packageList[0].names
          })
      }
    },
    /* 查看 */
    goTry(item) {
      if (
        this.IsPurchase == 1 &&
        item.seeNum &&
        item.seeNum > 0 &&
        this.userInfo
      ) {
        this.tryWatch = false
        this.names = item.names
      }
      if (this.IsPurchase == 0) {
        this.tryWatch = true
        this.names = item.names
      }
      // this.$router.push({
      //   path: '/VIP/sourcePage',
      //   query: {
      //     seeNum: item.seeNum,
      //     names: item.names,
      //     id: this.id,
      //     title: this.title,
      //     schoolId: this.schoolId,
      //     type: 2,
      //     IsPurchase: this.IsPurchase != 1
      //   }
      // })
    },
    goOrder() {
      const token = getToken()
      if (token) {
        orderClient
          .addOrders(
            this.id,
            localStorage.getItem('userId'),
            this.title,
            5,
            this.title
          )
          .then((res) => {
            if (res.code == 0) {
              /*   this.users = res.msg; */
              this.$router.push({
                name: '高校个人中心订单页',
                params: { orderNumber: res.msg }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    /* 下载资料 */
    dowInfo(item) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (this.IsPurchase == 0) {
        if (item.names) {
          const data = {
            zlId: item.mid,
            xmId: item.kId,
            dlId: item.pid,
            linkLocation: item.names,
            type: 1
          }
          dataClient.downloadRecord(data)
          Vue.prototype.DownloadDoc(item.names, item.title)
        } else {
          this.$notify({
            title: '错误',
            message: '暂无资源',
            type: 'error',
            duration: 2000
          })
        }
      } else {
        this.$notify({
          title: '错误',
          message: '未购买，无法查看, 请先去购买',
          type: 'error',
          duration: 2000
        })
      }
    },
    openKowledge(item) {
      this.kowledgePointsVisible = true
      this.$nextTick(() => {
        this.$refs.kowledgePoints.getData(item.mid)
      })
    },
    kowledgePointsClose() {
      this.kowledgePointsVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.gouBack {
  color: #666666;
  font-size: 14px;
  cursor: pointer;
  width: 1220px;
  margin: 20px auto;
}
.ziliaoBox {
  margin: 21px auto 0;
  width: 1200px;
  .iframeBox {
    position: relative;
    .maskLayer {
      position: absolute;
      bottom: 0;
      width: 790px;
      height: 721px;
      .bottom {
        position: absolute;
        bottom: 0;

        .shadow {
          width: 790px;
          height: 193px;
          background: linear-gradient(
            180deg,
            rgba(244, 247, 255, 0) 0%,
            #ffffff 100%
          );
          border-radius: 10px 10px 0px 0px;
          opacity: 1;
        }
        .goBuy {
          width: 790px;
          height: 48px;
          background: #ffffff;
          border-radius: 0px 0px 10px 10px;
          opacity: 1;

          text-align: center;
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333;
          line-height: 48px;
          span {
            cursor: pointer;
            color: #1e82f5;
          }
        }
      }
    }
    .title {
      width: 790px;
      padding: 20px;
      height: 88px;
      background: #fff;
      opacity: 1;
      .til {
        width: 326px;
        height: 24px;
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        line-height: 24px;
      }
      .tips {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 13px;
      }
      .up {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 12px;
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        margin-right: 40px;
        img {
          width: 12px;
          vertical-align: middle;
          margin-right: 3px;
        }
      }
    }
  }
}
.word-div {
  height: 700px;
}
.bigBox {
  position: relative;
  width: 390px;
  height: 697px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  margin-bottom: 80px;
  .directory {
    position: absolute;
    top: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    background: #f5f7ff;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
    padding-left: 21px;
    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      img {
        width: 16px;
        margin-right: 6px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 14px;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 14px;
      img {
        width: 14px;
        vertical-align: middle;
        margin-left: 6px;
      };
      padding-right: 30px;
    }
  }
  .buyBox {
    position: absolute;
    bottom: 21px;
    display: flex;
    padding-left: 23px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 56px;
    .btn {
      width: 228px;
      height: 56px;
      background: linear-gradient(88deg, #0270e8 0%, #21b4f5 100%);
      opacity: 1;
      text-align: center;
      font-size: 16px;
      border-radius: 28px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 56px;
      cursor: pointer;
    }
    .price {
      height: 33px;
      font-size: 24px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #fb2d25;
      line-height: 33px;
      margin-right: 58px;
    }
  }
  .itemBox {
    padding-top: 52px;
    overflow: auto;
    height: 600px;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .boxItem {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 59px;
      background: #f8faff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      .contentItem {
        padding-left: 24px;
        display: flex;
        align-items: center;
        position: relative;
        .seeBtn {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        .sourceImg {
          .imgItem {
            width: 17px;
            height: 18.66px;
            margin-right: 8px;
          }
        }

        .contentBox {
          .contentTitle {
            width: 262px;
            height: 14px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 14px;
          }
          .contentTips {
            font-size: 12px;
            color: #a78888;
          }
        }
      }

      .btnItem {
        position: absolute;
        right: 32px;
        width: 46px;
        height: 18px;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 1px solid #2586f5;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #2586f5;
        text-align: center;
        cursor: pointer;
      }
      .kowledge_Btn {
        width: 74px;
        height: 30px;
        border-radius: 4px;
        border: 1px solid #dddddd;
        color: #666666;
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        margin-right: 20px;
        cursor: pointer;
      }
      .dowBtn {
        width: 60px;
        height: 30px;
        border: 1px solid #ff5e51;
        border-radius: 4px;
        color: #ff5e51;
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
      }
    }
  }
}
.brace {
  width: 100%;
  height: 88px;
}
// .buyBox {
//   width: 100%;
//   height: 88px;
//   background-color: #ffffff;
//   box-shadow: 0px -4px 8px 1px rgba(204, 204, 204, 0.20000000298023224);
//   position: absolute;
//   bottom: 270px;
//   .priceBox {
//     width: 1220px;
//     height: 88px;
//     margin: auto;
//     display: flex;
//     justify-content: end;
//     .buyBtnBox {
//       display: flex;
//       align-items: center;
//       .price {
//         margin-right: 20px;
//         .symbol {
//           font-size: 18px;
//           color: #ff5e51;
//         }
//         .num {
//           font-size: 32px;
//           color: #ff5e51;
//         }
//       }
//       .buyBtn {
//         width: 170px;
//         height: 48px;
//         background-color: #ff5e51;
//         color: #ffffff;
//         text-align: center;
//         line-height: 48px;
//         border-radius: 4px;
//         cursor: pointer;
//       }
//     }
//   }
// }

.pack-box {
  position: relative;
  min-height: 80vh;
  height: 100%;
  // .buyBox {
  //   bottom: 0px !important;
  // }
}
.pack-box3 {
  .buyBox {
    // bottom: -20px !important;
  }
}
/*.pack-box1 {
  .buyBox {
    bottom: 440px !important;
  }
} */
.kowledgePoints_wrap {
  padding-bottom: 20px;

  .determine_btn {
    width: 40%;
    height: 40px;
    margin: 0 auto;
    margin-top: 20px;
    background-color: #ff5e51;
    color: #ffffff;
    font-weight: 500;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
}
.iframe {
  width: 790px;
  overflow: hidden;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  margin-right: 20px;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  iframe {
    width: 807px;
  }
}
</style>
